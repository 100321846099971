import React from 'react'
import '../App.css'

const Footer = () => {
	const thisYear = new Date().getFullYear()

	return (
		<div>
			<footer className='Footer'>&copy; {thisYear} Dunlevy Publishing</footer>
			<p>
				<a
					href='https://whc.ca/green-powered/?aff=3153&gbid=1en'
					onClick="window.open(this.href, 'popupWindow', 'width=450, height=538, status=no, scrollbars=no, menubar=no'); return false;">
					<img
						src='https://s.whc.ca/badges/green-badge-1.svg'
						width='130'
						alt='Green Hosting Badge'
					/>
				</a>
			</p>
		</div>
	)
}

export default Footer
