import React from 'react'
import '../App.css'

const Header = () => {
	return (
		<header className='App-header'>
			<table>
				<tbody>
					<tr>
						<td>
							<img
								src='https://upload.wikimedia.org/wikipedia/commons/9/96/Animated-Flag-Nigeria.gif'
								alt='Nigeria Flag'
								width='55px'
							/>
						</td>
						<td>
							<h1>Nollywood Movie Database</h1>
						</td>
						<td>
							<img
								src='https://upload.wikimedia.org/wikipedia/commons/9/96/Animated-Flag-Nigeria.gif'
								alt='Nigeria Flag'
								width='55px'
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</header>
	)
}

export default Header
