import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Image, Button, Card } from 'react-bootstrap'
import '../App.css'
import axios from 'axios'

const MovieScreen = ({ match }) => {
	const [movie, setMovie] = useState({})
	const { id } = useParams()

	/*
	useEffect(() => {
		const fetchMovie = async () => {
			const { data } = await axios.get(
				`http://localhost:3300/movie/${match.params.id}`
			)

			setMovie(data)
		}

		fetchMovie()
	}, [match])
*/
	//console.log(match.params.id)
	//console.log(movie)

	useEffect(() => {
		const fetchMovie = async () => {
			axios
				.get(`https://nollywood.eastdonriver.com/naija/movies/${id}`)
				.then((response) => {
					setMovie(response.data[0])
				})
		}

		fetchMovie()
	}, [match])

	return (
		<div className='MoviePage'>
			<Card className='MovieScreen' style={{ backgroundColor: '#282c34' }}>
				<Card.Body>
					<Row
						style={{
							justifyContent: 'center',
							padding: '0px 0px 15px 0px',
							margin: '0px',
						}}>
						<h2>{movie.title}</h2>
					</Row>
					<Row>
						<Col md={12}>
							<Button
								className='btn btn-block btn-secondary my-3'
								href={movie.netflix}
								target='_blank'
								rel='noopener noreferrer'>
								Netflix
							</Button>
							<Button
								className='btn btn-block btn-secondary my-3'
								href={movie.imdb}
								target='_blank'
								rel='noopener noreferrer'>
								IMDb
							</Button>
							<Button
								className='btn btn-block btn-secondary my-3'
								href={movie.wikipedia}
								target='_blank'
								rel='noopener noreferrer'>
								Wikipedia
							</Button>
							<Link className='btn btn-block btn-secondary my-3' to='/'>
								Home
							</Link>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Link to='/'>
								<Image
									className='SinglePhoto'
									src={movie.image}
									alt={movie.title}
								/>
							</Link>
						</Col>
					</Row>

					<Row
						style={{
							justifyContent: 'center',
							padding: '5px 0px 0px 0px',
							margin: '0px',
							color: 'white',
						}}>
						<h6>Released: {movie.year}</h6>
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}

export default MovieScreen
